import React from "react";
import { colors } from "~/app/providers/Theme";

export const CloudUploadIcon = () => {
  return (
    <svg width='71' height='71' viewBox='0 0 71 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.4529 56.6014C14.8979 56.6054 11.4664 55.2912 8.8159 52.9106C6.16535 50.5301 4.48213 47.2505 4.08871 43.7002C3.69529 40.15 4.61934 36.5787 6.68381 33.6705C8.74829 30.7624 11.808 28.7219 15.2768 27.94C14.2732 23.2366 15.1701 18.3252 17.7703 14.2862C20.3704 10.2473 24.4608 7.41165 29.1416 6.40315C33.8224 5.39466 38.7102 6.2959 42.7297 8.90861C46.7492 11.5213 49.5712 15.6315 50.5749 20.3349H50.9358C55.411 20.3304 59.7283 21.997 63.0495 25.011C66.3707 28.0251 68.4588 32.1717 68.9086 36.6458C69.3584 41.1199 68.1376 45.6023 65.4834 49.2229C62.8291 52.8435 58.9307 55.3439 54.545 56.2388M47.3266 45.7215L36.499 34.8415M36.499 34.8415L25.6714 45.7215M36.499 34.8415V66'
        stroke={colors.primary}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
